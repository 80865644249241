import { Background } from './pages/background'
import { Page } from './pages/page'

import './components/page-sidebar/page-sidebar'
import './components/subpage-header/subpage-header'

import './subpage.less'

export class SubPage extends Page {
  constructor() {
    super()

    Background.getInstance().setDefaultBackground()
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const page = new SubPage()
